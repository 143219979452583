<template>

<app-wrapper class="banner" v-bgimage="$state.page.banner" />

</template>

<script>

export default {

}

</script>

<style scoped>

.banner {
	background-position: 50% 0%;
	background-size: cover;
	background-color: #eee;
	height: 650px;
}

.is-tablet .banner {
	height: 460px;
}

.is-mobile .banner {
	height: 180px;
}

</style>
